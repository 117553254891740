<template>
    <div class="quote">
        <div class="start"><SvgQuoteStart/></div>
        <p class="content" v-html="text"></p>
        <div class="stop"><SvgQuoteStop class="stop"/></div>
    </div>
</template>

<script>
import SvgQuoteStart from './../../svg/quote-start'
import SvgQuoteStop from './../../svg/quote-stop'
export default {
    name: 'quote',
    components:
    {
        SvgQuoteStart, SvgQuoteStop
    },
    props:
    {
        text: String
    }
}
</script>

<style lang="scss">
    
    
    
    

    .quote
    {
        text-align:center;
        position:relative;
        display:flex;
        .content
        {
            flex:1;
            padding:0 1rem;
            margin-bottom:0;
            font-size: 18px;
            font-weight:500 !important;
        }
        svg
        {
            width:32px;
            height:32px;
            @include media-breakpoint-up(lg)
            {
                width:60px;
                height:60px;
            }
        }
        .start
        {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @include customize-gradient;
        }
        .stop
        {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include customize-gradient;
        }        
    }
</style>